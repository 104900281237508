import React from "react";

import { Container, Row, Col, Button } from "react-bootstrap";

import imgC from "../../assets/image/l1/png/l1-content1-img.png";
import { Link } from "gatsby";

const Content = () => {
  return (
    <>
      {/* <!-- Content Area --> */}
      <div className="content-section pt-12 pb-13 pt-lg-21 pb-lg-22">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col sm="10" lg="5" className="pe-lg-9">
              <div className="content-text">
                <h2 className="fw-light gr-text-5 mb-9 mb-lg-10 headings-color">
                  Techies in Practice and in Spirit
                </h2>
                <p className="gr-text-10 mb-0 text-black">
                Over a 4 years of Proven Technology Experience in Both Government & Commercial Markets.
                  <br /><br />
                  Our teams are honed by domain experts who help you make pragmatic decisions in selecting the right infrastructure and tools without losing sight of your product vision.
                </p>
                {/*Link
                  to="/contact"
                  className="btn-link with-icon gr-text-blue gr-text-7 fw-bold mt-9"
                >
                  Talk to an Expert{" "}
                  <i className="icon icon-tail-right fw-bold"></i>
                </Link>*/}
              </div>
            </Col>
            <Col sm="10" lg="6" className="offset-lg-1 mt-10 mt-lg-0">
              <div
                className="content-img img-group-1"
                data-aos="zoom-in"
                data-aos-duration="750"
              >
                <img className="img-fluid" src={imgC} alt="" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Content;
