import React from "react";
import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/landing1/Hero";
import Slider from "../components/Core/Slider";
import Services from "../sections/landing1/Services";
import Content1 from "../sections/landing1/Content1";
import Fact from "../sections/landing1/Fact";
import Feature from "../sections/landing1/Feature";
import Cta1 from "../sections/landing1/Cta1";
import CaseStudies from "../sections/landing1/CaseStudies";
import Testimonial2 from "../sections/landing1/Testimonial2";
import Cta2 from "../sections/landing1/Cta2";
import Content2 from "../sections/landing9/Content2";
import Content3 from "../sections/landing4/Content1";
import Content4 from "../sections/landing6/Content3";
import Partners from "../sections/landing2/Partners";
import Clients from "../sections/landing2/Clients";
import Affiliations from "../sections/landing2/Affiliations";

const IndexPage = () => {
  return (
    <>
      <PageWrapper
        footerConfig={{
          style: "style1", //style1, style2
        }}
        headerConfig={{
          align: "right",
          button: "account", // cta, account, null
        }}
      >
        <Slider />
        {/* <Hero /> */}
        <Services />
        <Partners />
        <Content3 />
        <br />
        <br />
        <Clients />
        <Content1 />
        <Affiliations />
        <Content4 />
        <Fact />
        <Cta1 />
      </PageWrapper>
    </>
  );
};
export default IndexPage;
