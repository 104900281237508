import React, { useContext } from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import GlobalContext from "../../context/GlobalContext";

import imgHero from "../../assets/image/slider1.jpeg";
import imgShape from "../../assets/image/l4/png/l4-hero-shape.png";

const Hero = () => {
  const gContext = useContext(GlobalContext);

  return (
    <>
      {/* <!-- Hero Area --> */}
      <div
        className="hero-area position-relative bg-blackish-blue pt-29 pt-lg-33 pb-15 pb-lg-29"
        style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh", /* Optionally, set the height of the container to 100% of the viewport height */

        }}  
      >
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col lg="6" md="9" className="order-lg-2">
              <div
                className="hero-img"
                data-aos="fade-left"
                data-aos-duration="750"
                data-aos-delay="500"
              >
                <div className="hero-video-thumb position-relative gr-z-index-1">
                  <img src={imgHero} alt="" className="w-100 rounded-8" />
                  <div className="abs-shape gr-abs-tr-custom gr-z-index-n1">
                    <img
                      src={imgShape}
                      alt=""
                      className="w-100"
                      data-aos="zoom-in"
                      data-aos-delay="750"
                      data-aos-duration="1000"
                    />
                  </div>
                </div>
              </div>
            </Col>
            <Col
              lg="6"
              md="9"
              className="order-lg-1 mt-9 mt-lg-0"
              data-aos="fade-right"
              data-aos-duration="500"
              data-aos-once="true"
            >
              <div className="hero-content dark-mode-texts">
                <h1 className="gr-text-2 mb-9 text-white">
                  Cloud Native Development <br />
                </h1>
                <p className="gr-text-8 pe-5 ps-0 pe-xl-11 mb-9 text-color-opacity">
                  Embrace the future with our Cloud Native Consulting services. From migrating existing applications to the cloud to developing and deploying cloud-native applications
                </p>
                <div className="hero-btn">
                  <Link
                    to="/contact"
                    className="btn-link with-icon text-green gr-text-7 fw-bold"
                  >
                    Contact us{" "}
                    <i className="icon icon-tail-right fw-bold"></i>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Hero;
