import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import imgC1 from "../../assets/image/l5/png/l5-service-card1.png";
import imgC2 from "../../assets/image/l5/png/l5-service-card2.png";
import imgC3 from "../../assets/image/l5/png/l5-service-card3.png";

import imgDot from "../../assets/image/l5/png/l5-dot-shape2.png";

const Services = () => {
  return (
    <>
      {/* <!--Service section  -->*/}
      <div className="service-section bg-default-4 pt-15 pb-13 py-lg-25">
        <Container>
          <Row className="">
            <Col md="9" lg="7" xl="6">
              <div className="section-title  mb-11 mb-lg-19 px-lg-3">
                <h2 className="fw-light gr-text-5 mb-9 mb-lg-8 headings-color">
                  Engagement Models
                </h2>
                <p className="gr-text-10 mb-0 text-color-opacity">
                  Learn how our experts can help you with software development
                </p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center position-relative gr-z-index-1">
            <Col
              md="6"
              lg="4"
              className="mb-9 mb-lg-0"
              data-aos="fade-right"
              data-aos-duration="750"
            >
              <div className="service-card gr-hover-shadow-2 d-flex flex-column text-center pt-15 px-9 pb-11 h-100 bg-default-3">
                <div className="card-img mb-11">
                  <img src={imgC1} alt="..." />
                </div>
                <span className="card-title gr-text-8 mb-6 text-black fw-bold">Product Development</span>
                <p className="gr-text-10 mb-0 text-color-opacity">
                  End-to-end custom software development. Your vision, our execution. Ideal for full cycle MVP design and development.
                </p>
                <a
                  href="/software-development"
                  className="gr-text-9 btn-link with-icon mt-auto"
                >
                  Learn more{" "}
                  <i className="icon icon-tail-right fw-bold"></i>
                </a>
              </div>
            </Col>
            <Col
              md="6"
              lg="4"
              className="mb-9 mb-lg-0"
              data-aos="fade-up"
              data-aos-duration="750"
            >
              <div className="service-card gr-hover-shadow-2 d-flex flex-column text-center pt-15 px-9 pb-11 h-100 bg-default-3">
                <div className="card-img mb-11">
                  <img src={imgC2} alt="..." />
                </div>
                <span className="card-title gr-text-8 mb-6 text-black fw-bold">Partners Consulting Services</span>
                <p className="gr-text-10 mb-0 text-color-opacity">
                  Setup a strategic offshore engineering center with an option to transfer the team.
                </p>
                <a
                  href="/partner-consulting-services"
                  className="gr-text-9 btn-link with-icon mt-auto"
                >
                  Learn more{" "}
                  <i className="icon icon-tail-right fw-bold"></i>
                </a>
              </div>
            </Col>
            <Col
              md="6"
              lg="4"
              className="mb-9 mb-lg-0"
              data-aos="fade-left"
              data-aos-duration="750"
            >
              <div className="service-card gr-hover-shadow-2 d-flex flex-column text-center pt-15 px-9 pb-11 h-100 bg-default-3">
                <div className="card-img mb-11">
                  <img src={imgC3} alt="..." />
                </div>
                <span className="card-title gr-text-8 mb-6 text-black fw-bold">Cloud Native Consulting</span>
                <p className="gr-text-10 mb-5 text-color-opacity">
                  Learn how our experts can help you with modernizing cloud infrastructure,
                  adopting Kubernetes, or building cloud native products.
                </p>
                <a
                  href="/cloud-native-consulting"
                  className="gr-text-9 btn-link with-icon  mt-auto"
                >
                  Learn more{" "}
                  <i className="icon icon-tail-right fw-bold"></i>
                </a>
              </div>
              <div
                className="gr-abs-br-custom gr-z-index-n1"
                data-aos="zoom-in-right"
                data-aos-duration="750"
              >
                <img src={imgDot} alt="" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Services;
