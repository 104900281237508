import React from "react";
// JSX
import HeroSlider, { Slide, Nav, OverlayContainer } from "hero-slider";

import Wrapper from "./UI/Wrapper/Wrapper";
import Title from "./UI/Title/Title";
import { Container, Row, Col, Button } from "react-bootstrap";

import imgB1 from "../../assets/image/l9/pic1.jpg";
import imgB2 from "../../assets/image/l9/pic2.jpg";
import imgB3 from "../../assets/image/l9/pic3.jpg";
import Hero from "../../sections/landing6/Slide1";
import H2 from "../../sections/landing6/Slide2";
import H3 from "../../sections/landing6/Slide3";


const app = () => {
  return (
    <HeroSlider
      // height="calc(100v/h - 250px)"
      autoplay={{
        autoplayDebounce: 0,
      }}
      controller={{
        initialSlide: 1,
        slidingDuration: 500,
        slidingDelay: 100,
        onSliding: (nextSlide) =>
          console.debug("onSliding(nextSlide): ", nextSlide),
        onBeforeSliding: (previousSlide, nextSlide) =>
          console.debug(
            "onBeforeSliding(previousSlide, nextSlide): ",
            previousSlide,
            nextSlide
          ),
        onAfterSliding: (nextSlide) =>
          console.debug("onAfterSliding(nextSlide): ", nextSlide)
      }}
    >
     <Slide
        shouldRenderMask
        background={{
          backgroundColor: "#13151c"
        }}
      >
        <H2/>
      </Slide>
      <Slide
        shouldRenderMask
        background={{
          backgroundColor: "#13151c"
        }}
      >
        <Hero/>
      </Slide>
      <Slide
        shouldRenderMask
        background={{
          backgroundColor: "#13151c"
        }}
      >
        <H3/>
      </Slide>
      <Nav />
    </HeroSlider>
  );
};

export default app;
