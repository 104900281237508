import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import imgAboutB from "../../assets/image/l5/jpg/l5-about-big.jpg";
import imgAboutM from "../../assets/image/l5/jpg/l5-about-mid.jpg";
import imgAboutP from "../../assets/image/l5/png/l5-about-pattern.png";
import imgAboutS from "../../assets/image/l5/jpg/l5-about-sm.jpg";
import icon1 from "../../assets/image/svg/l5-feature-icon1.svg";
import icon2 from "../../assets/image/svg/l5-feature-icon2.svg";
import icon3 from "../../assets/image/svg/l5-feature-icon3.svg";
import icon4 from "../../assets/image/svg/l5-feature-icon4.svg";

const Content1 = () => (
  <>
    {/* <!-- Content section 1 --> */}
    <div className="content-section pt-lg-25 pt-15 pb-10 pb-lg-25 bg-default-2">
      <Container>

        <Row>
          <Col lg="5">
            <div className="section-title">
              <p className="pre-title gr-text-11 text-red fw-bold mb-6">
                Why we are different?
              </p>
              <h2 className="fw-light gr-text-5 mb-9 mb-lg-8 headings-color">
                Why do Companies Prefer Working with Us.
              </h2>
            </div>
            
            <div
              className="about-image img-big pt-lg-8"
              data-aos="fade-right"
              data-aos-duration="800"
            >
              <p className="gr-text-10 mb-0 text-black">
                We offer reliable, efficient delivery with high-caliber engineers & finely-tuned software development processes. <br /><br />
                Save up to 30% of your time immediately after the initial kickoff with our reusable code libraries & solutions.
              </p>
            </div>
          </Col>
          <Col lg="7">
            <Row className="justify-content-between align-items-center ps-lg-13">
            < Col
                md="10"
                className="mt-11"
                data-aos="fade-right"
                data-aos-duration="800"
                data-aos-delay="600"
              >
                <div className="feature-widget media">
                  <div className="widget-icon p-7 rounded-15 me-9 gr-bg-green-opacity-1">
                    <img src={icon4} alt="" />
                  </div>
                  <div className="widget-text">
                    <p className="title gr-text-7 mb-6 heading-color fw-bold">
                      Software architecture & scale
                    </p>
                    <p className="gr-text-10 mb-0 text-black">
                    We have architected scalable & easy to maintain  software systems for startups serving millions of customers.
                    </p>
                  </div>
                </div>
              </Col>
              <Col
                md="10"
                className="mt-11"
                data-aos="fade-right"
                data-aos-duration="800"
                data-aos-delay="600"
              >
                <div className="feature-widget media">
                    <div className="widget-icon p-7 rounded-15 me-9 gr-bg-red-opacity-1">
                      <img src={icon2} alt="" />
                    </div>
                    <div className="widget-text">
                      <p className="title gr-text-7 mb-6 heading-color fw-bold">Performance tracking</p>
                      <p className="gr-text-10 mb-0 text-black">
                      Monitor progress with daily standups, weekly sprints and demos. Jira, Asana, Monday, Rally: we adopt the project management tools you prefer.
                      </p>
                    </div>
                  </div>
              </Col>
              <Col
                  md="10"
                  className="mt-11"
                  data-aos="fade-right"
                  data-aos-duration="800"
                  data-aos-delay="600"
              >
                <div className="feature-widget media">
                    <div className="widget-icon p-7 rounded-15 me-9 gr-bg-blue-opacity-1">
                      <img src={icon1} alt="" />
                    </div>
                    <div className="widget-text">
                      <p className="title gr-text-7 mb-6 heading-color fw-bold">
                        Handpick your team
                      </p>
                      <p className="gr-text-10 mb-0 text-black">
                        Find best-match engineers for your team. Request code samples or interview to decide who gets to join your squad..
                      </p>
                    </div>
                  </div>
              </Col>
              <Col
                md="10"
                className="mt-11"
                data-aos="fade-right"
                data-aos-duration="800"
                data-aos-delay="600"
              >
                <div className="feature-widget media">
                  <div className="widget-icon p-7 rounded-15 me-9 gr-bg-red-opacity-1">
                    <img src={icon3} alt="" />
                  </div>
                  <div className="widget-text">
                    <p className="title gr-text-7 mb-6 heading-color fw-bold">Real-time collaboration</p>
                    <p className="gr-text-10 mb-0 text-black">
                      Seamless integration with your in-house team with three hours of guaranteed overlap.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  </>
);

export default Content1;
